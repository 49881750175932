/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Interface
import { FormProps } from 'components/flex/Form/Shell'

const Background = styled.div`
  background: #f8f8f8;
  height: 20vh;
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  border-radius: 24px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  margin-top: -8rem;
  background-color: ${({ theme }) => theme.color.light};

  & .form-duuf-group {
    position: relative;
    margin-bottom: 2rem;

    &-consent {
      & > div {
        align-items: flex-start !important;
      }

      & a:hover {
        text-decoration: underline;
      }
    }
  }

  .form-duuf-checkbox-consent {
    margin-right: 8px;
    margin-top: 3px;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox {
    margin-top: 3px;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
    position: absolute;
    right: 10px;
    top: 25px;

    &-consent {
      margin-top: 25px;
    }
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const Icon = styled(Plaatjie)`
  height: 31px;
  width: 31px;
`

const Socials = styled.div`
  & a {
    font-size: 0;
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
    }
  }
  & p {
    line-height: 20px;
  }
`

const FormQuote: React.FC<FormProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <Background />
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          {/* @ts-ignore */}
          <StyledFormDuuf
            id={3}
            className="p-4 p-lg-5"
            generate={false}
            privacyUrl="/privacyverklaring"
            privacyUrlPlacement="privacy voorwaarden"
          >
            <Content content={fields.description} className="mb-5" />
            <div className="row">
              <div className="col-md-6">
                <div className="mx-sm-4">
                  <FormDuufGroup hideIds={[2, 3, 9]} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mx-sm-4">
                  <FormDuufGroup showIds={[9, 2, 3]} />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <FormDuufSubmit />
            </div>
            <div className="mt-5 row">
              {fields.columns?.map((column, index) => (
                <div className="col-sm-6 col-md-4 mb-4">
                  <Content key={index} content={column?.description} />
                </div>
              ))}
            </div>
            <Socials className="d-flex justify-content-center mt-5">
              {fields.socials?.map((social, index) => (
                <a key={index} className="me-3" href={social?.link?.url}>
                  <Icon image={social?.icon} alt="" />
                  {social?.link?.title}
                </a>
              ))}
            </Socials>
          </StyledFormDuuf>
        </div>
      </div>
    </div>
  </section>
)

export default FormQuote
